<template>
  <audio id="soundHandle" class="d-none" style="z-index: 9999;" controls>
    <source src="/data/AR02_2.mp3" type="audio/mpeg">
  </audio>
  <button id="startbtn" class="d-none">Start</button>
  <canvas
    :class="{ 'd-none': !this.isBardcodeDetected, 'object-video-potrait': isPortrait(), 'object-video': !isPortrait() }"
    ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>

  <div v-if="pageLoader" class="loaderContainer">
    <div class="spinner-border loaderContent" style="color:#D2B071" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-if="splashScreenLoader || !userInteraction" class="splashScreenLoaderContainer">
    <span class="align-middle">
      <div class="row justify-content-center ">
        <img src="/data/logo.png" alt="" style="    width: 300px;">

      </div>
      <div v-if="splashScreenLoader" class="row justify-content-center">
        <div class="w-50 progress p-0">
          <div class="progress-bar" role="progressbar" :style="'width:' + imagesLoadedCount * 100 / imageCount + '%;'"
            :aria-valuenow="imagesLoadedCount * 100 / imageCount" aria-valuemin="0" aria-valuemax="100">{{
              Math.round(imagesLoadedCount * 100 / imageCount) }}%</div>
        </div>
      </div>
      <div v-else class="col-12 px-3">
        <!-- <button class="btn start-button" @click="userInteraction = true; initBarcodeScanner()">Start Now</button> -->

        <button class="btn start-button mt-2" @click="userInteraction = true; playContent()">Start Now</button>

        <p v-if="isPortrait()" class="mt-2">
          <img src="/data/responsive.png" style="width:40px">
          Please rotate your phone for better experience.
        </p>
      </div>
    </span>
  </div>


  <div ref="scene" style="    display: block;
    width: 932px;
    height: 815px;
    background: transparent;
    position: fixed;
    z-index: 1;"></div>


  <div class="scanner-container">
    <!-- <div :class="isLoading ? 'd-none' : ''"> -->
    <video poster="data:image/gif,AAAA" ref="scanner" width="220" height="340">
    </video>
    <div v-if="!isBardcodeDetected" class="overlay-element"></div>
    <div v-if="!isBardcodeDetected" class="laser"></div>
    <!-- </div> -->
  </div>


  <!-- v-if="contactForm" -->
  <div v-if="contactForm" class="card info-card fadeInCard">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-3" style="
    display: inline-table;
    align-self: center;
">
          <img class="info-logo" src="/data/logo.png">
        </div>
        <div class="col-9">
          <p class="text-left info-modal-title text-uppercase">Thank you for scanning. Saffola Honney will be donating rs.
            1 to sunderban
            foundation on
            your behalf</p>

          <div class="w-100 ms-2" v-if="!formSubmitted">
            <p class="info-modal-label mb-1 text-start">Enter your Mobile No.</p>
            <div class="d-flex">
              <input type="number" class="form-control w-75" v-model="contact_no" maxlength="10" max="10"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
              <div class="w-25"><span class="sendBtn" role="button" @click="submitInfo"><i class="bi bi-send-fill"
                    style="color: #D2B071;"></i></span>
              </div>
            </div>

          </div>
          <div v-else class="w-100 ms-2 info-modal-label">
            Thank you for sharing your Mobile No.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from "@zxing/library";

export default {
  name: "stream-barcode-reader",

  data() {
    return {
      isLoading: true,
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices,
      isBardcodeDetected: true,
      pageLoader: false,
      isDragging: true,
      previousMousePosition: { x: 0, y: 0 },
      mouse: { x: 0, y: 0 },
      loader: false,
      images: [], // array to store image objects
      currentFrame: 0,
      fps: 32,
      canvasWidth: null, // Set your canvas width
      canvasHeight: null, // Set your canvas height
      intervalId: null,
      splashScreenLoader: false,
      imagesLoadedCount: 0,
      imageCount: 743,
      audioSrc: "/data/AR02_2.mp3",
      audioStarted: false,
      audioElement: null,
      startTime: new Date(),
      userInteraction: false,
      contactForm: false,
      formSubmitted: false,
      contact_no: null,
      contentPlayedCount: 0
    };
  },
  async mounted() {
    this.canvasWidth = window.innerWidth
    this.canvasHeight = window.innerHeight
    // window.addEventListener('resize', () => {
    //   if (this.currentFrame * 100 / this.imageCount > 85) {
    //     setInterval(() => {
    //       this.renderNextFrame()
    //     }, 2000);
    //   }
    // });
    // this.$refs.infomodalbtn.click();
    window.addEventListener('resize', this.handleResize);
    this.requestVideoPermission()
    this.preloadAudio()


    //Barcode Scanning
    if (!this.isMediaStreamAPISupported) {
      throw new Exception("Media Stream API is not supported");
    }

    this.$refs.scanner.oncanplay = (event) => {
      event;
      this.isLoading = false;
      this.$emit("loaded");
    };

    //Sequence
    const imageCount = this.imageCount; // Number of images in the sequence
    const imageBasePath = '/data/vision/'; // Base path of your images
    const batchSize = 100; // Batch size


    this.splashScreenLoader = true;
    this.imagesLoadedCount = 0;

    for (let i = 0; i < imageCount; i += batchSize) {
      const imagesBatch = await this.loadImageBatch(i + 1, batchSize, imageBasePath);
      this.images.push(...imagesBatch);

      this.imagesLoadedCount += imagesBatch.length;
      const percentage = (this.imagesLoadedCount * 100) / imageCount;

      if (percentage > 90) {
        this.splashScreenLoader = false;

      }
      // if(percentage>50){

      // }
    }
  },

  beforeUnmount() {
    this.codeReader.reset();
  },
  computed: {
    isPortraitCheck() {
      return window.innerHeight > window.innerWidth;
    }
  },
  methods: {
    handleResize() {
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerHeight;
    },
    submitInfo() {
      if (this.contact_no && this.contact_no.toString().length == 10) {
        this.formSubmitted = true;
      } else {

        alert(`Please enter valid mobile no.`)
      }
    },
    playContent() {
      this.goFullScreen()
      this.initBarcodeScanner();
      this.pageLoader = true
      setTimeout(() => {
        this.startTime = new Date();
        this.imageCount = 743
        this.playAudio()
        requestAnimationFrame(this.renderNextFrame);
        this.contentPlayedCount = 1;
        this.pageLoader = false
      }, 2000);

    }
    ,
    initBarcodeScanner() {


      this.codeReader.decodeFromVideoDevice(undefined, this.$refs.scanner, (result, err) => {
        // var soundHandle
        result
        err
        // this.canvasWidth = window.innerWidth // Set your canvas width
        // this.canvasHeight = window.innerHeight

        // this.pageLoader=true
        if (this.contentPlayedCount == 0) {
          // setTimeout(() => {
          // this.pageLoader=false
          // this.startTime = new Date();
          // this.imageCount = 498
          // this.playAudio()
          // requestAnimationFrame(this.renderNextFrame);
          // this.contentPlayedCount = 1;

          // }, 1000);
        }




        // err;
        // if (result) {
        //   if (!this.isBardcodeDetected) {
        //     console.log(result.text)
        //     if (result.text == "https://tiedinabow.in/saffola/") {
        //       // console.log("saffola model")
        //       this.isBardcodeDetected = true
        //       setTimeout(() => {
        //         if (this.isBardcodeDetected) {
        //           // this.startSequence();
        //           this.startTime = new Date();
        //           this.imageCount = 498
        //           this.playAudio()
        //           requestAnimationFrame(this.renderNextFrame);

        //         }
        //       }, 500);
        //     } else {
        //       // console.log("other text");
        //       // this.isBardcodeDetected = false
        //       // this.imageCount=0
        //       // soundHandle = document.getElementById('soundHandle');
        //       // if (!soundHandle.paused && !soundHandle.ended) {
        //       //   soundHandle.pause()
        //       //   soundHandle.currentTime = 0
        //       //   this.audioStarted = false
        //       // }

        //     }
        //   }
        // } else {
        //   // console.log("no barcode");
        //   // this.isBardcodeDetected = false
        //   // this.imageCount=0
        //   // soundHandle = document.getElementById('soundHandle');
        //   // if (!soundHandle.paused && !soundHandle.ended) {
        //   //   soundHandle.pause()
        //   //   soundHandle.currentTime = 0
        //   //   this.audioStarted = false
        //   // }
        // }
      });
    },
    getTimeDifferenceInSeconds(startTime) {
      const currentTime = Date.now();
      const differenceInSeconds = (currentTime - startTime) / 1000;
      return differenceInSeconds;
    },
    requestVideoPermission() {
      // Check if getUserMedia is supported
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Check if permission is already granted
        navigator.permissions.query({ name: 'camera' })
          .then(permissionStatus => {
            if (permissionStatus.state === 'granted') {
              // Permission already granted, do nothing or perform necessary actions
            } else {
              // Permission not granted, request it
              navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                  stream
                  // Handle the stream or perform actions after getting permission
                })
                .catch(function (error) {
                  alert("Error accessing camera: " + error);
                });
            }
          })
          .catch(error => {
            console.error('Error checking camera permission:', error);
          });
      } else {
        // Browser doesn't support getUserMedia
        alert("getUserMedia not supported in this browser");
      }
    },
    async preloadAudio() {
      try {
        const response = await fetch(this.audioSrc);
        const audioBlob = await response.blob();
        const objectURL = URL.createObjectURL(audioBlob);

        this.audioElement = new Audio();
        this.audioElement.src = objectURL;

        console.log('Audio file downloaded and ready for playback');
      } catch (error) {
        console.error('Error preloading audio:', error);
      }
    },
    playAudio() {
      if (this.audioElement) {
        // this.audioElement.play();
        var soundHandle = document.getElementById('soundHandle');
        soundHandle.currentTime = 0
        soundHandle.play();
      } else {
        // alert("dede")
      }
    },
    async loadImageBatch(startIndex, batchSize, imageBasePath) {

      const images = [];
      for (let i = startIndex; i < startIndex + batchSize; i++) {
        const img = new Image();
        const paddedIndex = String(i).padStart(5, '0');
        if (i < this.imageCount) {
          img.src = `${imageBasePath}AR_02_op02${paddedIndex}.webp`;

          images.push(
            new Promise((resolve) => {
              img.onload = () => resolve(img);
              img.onerror = () => resolve("");
            })
          );
        }
      }
      return Promise.all(images);
    },
    renderNextFrame() {
      // console.log("render frame")
      // Update your logic to render the current frame
      const canvas = this.$refs.canvas; // Assuming you have a canvas element
      const ctx = canvas.getContext('2d');

      if (this.isPortrait()) {
        var wrh = this.images[this.currentFrame].width / this.images[this.currentFrame].height;
        var newWidth = canvas.width;
        var newHeight = newWidth / wrh;
        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * wrh;
        }
        var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
        var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

        const ratio = newWidth / newHeight

        newWidth = newWidth + 200;
        newHeight = newWidth / ratio;
        xOffset = xOffset - 100
        yOffset = yOffset - 30
      } else {

        var wrh = this.images[this.currentFrame].height / this.images[this.currentFrame].width;
        // var newWidth = canvas.width;
        // var newHeight = newWidth / wrh;

        var newHeight = canvas.height;
        var newWidth = newHeight / wrh;
        if (newHeight > canvas.height) {
          // newWidth = canvas.width;
          // newWidth = newWidth * wrh;
        }
        var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
        var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

        const ratio = newWidth / newHeight

        // newWidth = newWidth;
        newHeight = newWidth / ratio;
        xOffset = xOffset - 0
        yOffset = yOffset + 0
      }


      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(this.images[this.currentFrame], xOffset, yOffset, newWidth, newHeight); // Draw the current frame

      // Update the frame count
      // this.currentFrame = (this.currentFrame + 1) % this.imageCount;

      this.currentFrame = Math.floor((this.getTimeDifferenceInSeconds(this.startTime) / 30) * this.imageCount);
      if (this.currentFrame >= this.imageCount) {
        this.currentFrame = this.imageCount
      }
      if (this.currentFrame * 100 / this.imageCount > 90) {
        // this.contactForm = true
        this.exitFullScreen()
      }
      if (this.currentFrame < this.imageCount - 2) {
        // Schedule the next frame
        setTimeout(() => {
          requestAnimationFrame(this.renderNextFrame);
          // console.log(this.currentFrame, this.getTimeDifferenceInSeconds(this.startTime));
          // if (!this.audioStarted) {
          //   this.audioStarted = true;
          //   this.playAudio();
          // }

          // this.playAudio();
        }, 1000 / this.fps);
      }
    },
    startSequence() {
      const frameDuration = 1000 / this.fps;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const startTime = new Date();

      this.intervalId = setInterval(() => {

        if (this.currentFrame < this.imageCount) {


          if (this.isPortrait()) {

            console.log("huhuhuh");
            var wrh = this.images[this.currentFrame].width / this.images[this.currentFrame].height;
            var newWidth = canvas.width;
            var newHeight = newWidth / wrh;
            if (newHeight > canvas.height) {
              newHeight = canvas.height;
              newWidth = newHeight * wrh;
            }
            var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
            var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

            const ratio = newWidth / newHeight

            newWidth = newWidth + 0;
            newHeight = newWidth / ratio;
            xOffset = xOffset - 0
            yOffset = yOffset - 10
          } else {
            var wrh = this.images[this.currentFrame].width / this.images[this.currentFrame].height;
            var newWidth = canvas.width;
            var newHeight = newWidth / wrh;
            if (newHeight > canvas.height) {
              newHeight = canvas.height;
              newWidth = newHeight * wrh;
            }
            var xOffset = newWidth < canvas.width ? ((canvas.width - newWidth) / 2) : 0;
            var yOffset = newHeight < canvas.height ? ((canvas.height - newHeight) / 2) : 0;

            const ratio = newWidth / newHeight

            // newWidth = newWidth + 10;
            // newHeight = newWidth / ratio;
            // xOffset = xOffset - 5
            // yOffset = yOffset + 0
          }
          ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
          ctx.drawImage(this.images[this.currentFrame], xOffset, yOffset, newWidth, newHeight);
          this.currentFrame = (this.currentFrame + 1);
          console.log(this.currentFrame, frameDuration, this.getTimeDifferenceInSeconds(startTime));
        }
      }, frameDuration);
      if (!this.audioStarted) {
        setTimeout(() => {
          this.audioStarted = true;
          this.playAudio();
        }, 900);
      }
    },

    onMouseDown(event) {
      this.isDragging = true
      this.previousMousePosition = {
        x: event.clientX,
        y: event.clientY,
      }
    },
    onTouchStart(event) {
      this.isDragging = true
      this.previousMousePosition = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      }
    },
    onMouseUp() {
      this.isDragging = false
    },
    onTouchEnd() {
      this.isDragging = false
    },
    onMouseMove(event) {
      this.mouse = {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      }
    },
    onTouchMove(event) {
      this.mouse = {
        x: (event.touches[0].clientX / window.innerWidth) * 2 - 1,
        y: -(event.touches[0].clientY / window.innerHeight) * 2 + 1,
      }
    },
    isPortrait() {
      return window.innerHeight > window.innerWidth;
    },
    goFullScreen() {
      const elem = document.documentElement; // Get the root element (the whole document)
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        if (elem.webkitRequestFullscreenWithKeys) { /* Latest Safari */
          elem.webkitRequestFullscreenWithKeys();
        } else {
          elem.webkitRequestFullscreen(); /* Older Safari */
        }
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }

      // Scroll to bottom
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      }, 500); // Adjust the delay as needed for different screen sizes
    },
    exitFullScreen() {
      if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  },
};
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.scanner-container {
  position: fixed;
  height: 100vh;
  width: -webkit-fill-available;
}

.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);

  -webkit-clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
}

.laser {
  width: 60%;
  margin-left: 20%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}

@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}

@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}

.loaderContent {
  height: 100px;
  width: 100px;
  margin-top: 40vh;
}

.splashScreenLoaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.ssloaderContent {
  position: fixed;
  top: 35%;
  left: 45%;
  height: 100px;
  width: 100px;
}

/* .object-video{
  background-color: transparent;
    position: fixed;
    top: 11px;
    width: 44%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    left: 31%;
    z-index: 9999;
} */

.object-video {
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  /* left: 80px; */
  z-index: 9999;
}

.object-video-potrait {
  background-color: transparent;
  position: fixed;
  /* top: -45vh; */
  /* width: 200%; */
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  /* left: -200px; */
  z-index: 9999;
  object-fit: cover;
}

.progress-bar {
  background-color: #D2B071 !important;
}

.start-button {
  width: 100%;
  background-color: #D2B071;
  color: #fff;
}

@media only screen and (min-width: 600px) {
  .info-card {
    position: fixed;
    top: 2%;
    background: rgb(220, 51, 46, 0.6);
    width: 35%;
    z-index: 9999;
    border: 3px solid #94ff94;
    left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .info-card {
    position: fixed;
    top: 70%;
    background: rgb(220, 51, 46, 0.6);
    width: 90%;
    z-index: 9999;
    border: 3px solid #94ff94;
    left: 5%;
  }
}

.info-logo {
  width: 140% !important;
}

.info-modal-title {
  font-size: 10px;
  font-weight: 900;
  color: yellow;
  text-shadow: -0.5px 0 #333;
  margin-bottom: 10px;
}

.info-modal-label {
  font-size: 11px;
  font-weight: 900;
  color: #94ff94;
  text-shadow: -0.5px 0 #333;
}

.sendBtn {
  background-color: yellow;
  width: 35px !important;
  height: 35px;
  display: inline-table;
  border-radius: 50%;
  padding-top: 7px;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #fff;
}

.fadeInCard {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
  animation-delay: 5.5s;
}
</style>