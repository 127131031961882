import { createRouter, createWebHistory } from 'vue-router';
import megaWall from './components/megaWall.vue';
import visionWall from './components/visionWall.vue';

const routes = [
  {
    path: '/mega-wall',
    name: 'mega-wall',
    component: megaWall
  },
  {
    path: '/vision-wall',
    name: 'vision-wall',
    component: visionWall
  }
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
