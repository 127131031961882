<template>
  <!-- <three-d-model /> -->
  <!-- <ImageSequence /> -->
  <router-view></router-view>
</template>

<script>
// import ThreeDModel from './components/3dModel.vue'
// import ImageSequence from './components/ImageSequence.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap"
export default {
  name: 'App',
  components: {
    // ThreeDModel,
    // ImageSequence
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
